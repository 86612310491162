import "./index.css";

import "./components/details-closer.ts";
import "./components/theme-selector.ts";
import "./components/pc-bookmark.ts";

async function init() {
  console.log("READY.");
}

init().catch(console.error);
